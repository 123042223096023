
import objectFitImages from 'object-fit-images';
import masonry from 'masonry-layout';


export const Site = {

    init: function() {

        objectFitImages();

        this.nonLinks();
        this.externalLinks();

        $('a[href^="#"]').on( 'click', this.sectionLinks.bind(this) );

    },

    nonLinks() {

        let $links = $('a[href="#"]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            elem.removeAttribute('href');

        } );

    },

    externalLinks() {

        let $links = $('a[href]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            if ( !elem.host || elem.target || elem.href.includes('#') )
                return;

            if ( elem.href.includes('.pdf') || ( elem.host !== window.location.host ) )
                elem.target = '_blank';

        } );

    },

    sectionLinks(e) {

        e.preventDefault();

        let $href = $(e.currentTarget).attr('href');
        let $header = $('[data-header]').height();

        $('html,body').animate({
            scrollTop: $($href).offset().top - $header - 14
        }, 'slow');

    }

};

export default Site;