
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const LoginPopup = {

    init: function() {

        this.$popup = $('[data-login-popup]');
        this.$links = $('[data-login-popup-page]');
        this.$toggle = $('[data-login-popup-toggle]');
        this.$redirect = $('[data-login-popup-redirect]');

        if ( !this.$popup.length || !this.$links.length || !this.$toggle.length || !this.$redirect.length )
            return;

        this.$form = this.$popup.find('[data-login-popup-form]');

        if ( !this.$form.length )
            return;

        this.removeLinks();
        
        this.$links.on( 'click', this.togglePopup.bind(this) );
        this.$toggle.on( 'click', this.togglePopup.bind(this) );
        this.$form.on( 'submit', this.ajaxLogin.bind(this) );

    },

    removeLinks() {

        this.$links.each( ( index, elem ) => {
            
            $(elem).removeAttr('href');

        } );

    },

    togglePopup( e ) {

        let $redirect = $('[data-login-popup-redirect]');
        let $forgotPassword = $('[data-login-popup-forgot-password]');
        let $usernameInput = $('[data-login-popup-username]');

        this.active = !this.active;

        this.$popup.fadeToggle().css('display', 'flex');

        if ( this.active ) {

            let $link = $(e.currentTarget).data('login-popup-page');

            if ( $link ) {
                $($redirect[0]).attr( 'data-login-popup-original', $($redirect[0]).val() );
                $($redirect[0]).val( $link );

                let $type = '';
                let $url = document.createElement('a');
                $url.href = $link;

                Object.keys(forgot_password_object).every( key => {
                    if ( $url.pathname.includes(key) ) {
                        $type = key;
                        return false;
                    }

                    return true;
                } );

                if ( $type ) {
                    $($forgotPassword[0]).attr( 'href', forgot_password_object[$type] );
                } else {
                    $($forgotPassword[0]).attr( 'href', forgot_password_object.general );
                }

            }

            if ( $usernameInput )
                $usernameInput.focus();
            
            disableBodyScroll( this.$popup[0] );

        } else {

            let $link = $($redirect[0]).data('login-popup-original');

            if ( $link ) {
                $($redirect[0]).val( $link );
                $($redirect[0]).removeAttr('data-login-popup-original');
            }

            $($forgotPassword[0]).attr( 'href', forgot_password_object.general );

            enableBodyScroll( this.$popup[0] );
            
        }

    },

    ajaxLogin( e ) {

        let $message = this.$form.find('[data-login-popup-message]');
        let $username = this.$form.find('[data-login-popup-username]');
        let $password = this.$form.find('[data-login-popup-password]');
        let $remember = this.$form.find('[data-login-popup-remember]');
        let $security = this.$form.find('#security');
        let $redirect = this.$form.find('[data-login-popup-redirect]');

        $message.show().html( ajax_login_object.loading_message );
        $message.removeClass('is-success');
        $message.removeClass('is-error');
        $username.removeClass('is-error');
        $password.removeClass('is-error');

        $.ajax( {
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajax_url,
            data: { 
                'action': 'ajaxlogin',
                'username': $username.val(),
                'password': $password.val(),
                'security': $security.val(),
                'remember_me': ( $remember.val() == 'forever' ? true : false )
            },
            success: ( data ) => {
                $message.html( data.message );
                $message.addClass('is-success');

                if ( data.logged_in == true ) {
                    document.location.href = $redirect.val();
                }
            },
            error: ( data ) => {
                $username.addClass('is-error');
                $password.addClass('is-error');
                $message.addClass('is-error');

                if ( data.status == 200 ) {
                    $message.html( ajax_login_object.incorrect_login );
                } else if ( data.status == 400 ) {
                    $message.html( 'Something went wrong, try <a href="' + ajax_login_object.admin_url + '">login screen</a> instead.' );
                } else {
                    $message.html( data.statusText );
                }
            }
        } );
        e.preventDefault();

    }
        
};

export default LoginPopup;