
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


export const Search = {

    init: function() {

        // might need to rename this function or incorporate into School Search
        this.$search = $('[data-search]');
        this.$toggle = $('[data-search-toggle]');
        this.$input = $('[data-search-input]');
        this.$background = $('[data-search-background]');
        this.$dismiss = $('[data-search-dismiss]');
        this.$anotherSearchInput = $('[data-search-form-input]');
        
        if ( this.$anotherSearchInput.length ) {
            this.$toggle.on( 'click', this.scrollToAnotherSearch.bind(this) );
            return;
        }

        if ( !this.$search.length || !this.$toggle.length || !this.$input.length || !this.$background.length || !this.$dismiss.length )
            return;

        this.$toggle.on( 'click', this.toggleSearch.bind(this) );
        this.$background.on( 'click', this.toggleSearch.bind(this) );
        this.$dismiss.on( 'click', this.toggleSearch.bind(this) );

    },

	toggleSearch() {

        this.active = !this.active;

        this.$toggle.toggleClass('is-active');
        this.$search.fadeToggle(200);
        this.$background.toggle();

        if ( this.active ) {
            this.$input.focus();
            disableBodyScroll();
        } else {
            this.$input.blur();
            enableBodyScroll();
        }

	},

    scrollToAnotherSearch() {

        $('html,body').animate({
            scrollTop: this.$anotherSearchInput.offset().top
        }, 'slow');

        this.$anotherSearchInput.focus();

    }

        
};

export default Search;