
export const Accordion = {

    init: function() {

        this.$accordion = $('[data-accordion]');
        this.$toggle = $('[data-accordion-toggle]');

        if ( !this.$accordion.length || !this.$toggle.length )
            return;

        this.$toggle.on( 'click', this.toggle.bind(this) );

    },

	toggle(e) {

        let $elem = $(e.currentTarget);
        let $parent = $elem.parent();
        let $content = $elem.next('[data-accordion-content');
        let $active = $parent.parent().find('.is-active');

        $parent.toggleClass('is-active');
        $content.slideToggle( 200 );

        if ( $active.length && $active[0] ) {
            let $activeContent = $active.find('[data-accordion-content]');

            if ( $active[0] === $parent[0] )
                return;

            $active.removeClass('is-active');
            $activeContent.slideToggle( 200 );
        }

    }

};

export default Accordion;