
export const MobilePanels = {

    init: function() {

        this.$offers = $('[data-mobile-panels]');

        if ( !this.$offers.length )
            return;

        this.$items = this.$offers.find('[data-mobile-panels-items]');
        this.$item = this.$offers.find('[data-mobile-panels-item]');
        this.$toggle = this.$offers.find('[data-mobile-panels-toggle]');
        this.$textShow = this.$offers.find('[data-mobile-panels-toggle-text-show]');
        this.$textHide = this.$offers.find('[data-mobile-panels-toggle-text-hide]');
        this.$qty = this.$toggle.data('mobile-panels-toggle');

        if ( !this.$item.length || !this.$toggle.length || !this.$textShow.length || !this.$textHide.length )
            return;

        $(window).on( 'resize', this.windowResize.bind(this) );
        this.$toggle.on( 'click', this.toggleOffers.bind(this) );

        this.$offers.each( (index, elem) => { this.resizeOffers(elem) } );

    },

    windowResize() {

        clearTimeout( this.$timeout );
        this.$timeout = setTimeout( () => {
            this.$offers.each( (index, elem) => { this.resizeOffers(elem) } );
        }, 50 );

    },

	resizeOffers( $elem ) {

        if ( !$elem )
            return;

        let $toggle = $($elem).find('[data-mobile-panels-toggle]');
        let $textShow = $toggle.find('[data-mobile-panels-toggle-text-show]');
        let $textHide = $toggle.find('[data-mobile-panels-toggle-text-hide]');

        let $qty = $toggle.data('mobile-panels-toggle');
        if ( !$qty || $qty < 2 )
            $qty = 6;

        let $items = $toggle.prev();
        let $width = window.innerWidth;
        let $item = $items.find('[data-mobile-panels-item]'); // need this - css columns has overflow target item

        let $target = $($item[$qty - 2]);
        let $targetNext = $($item[$qty -1 ]);

        let $height = $target.offset().top - $($items).offset().top + $target.height() + $targetNext.height();

        if ( $width >= 750 ) {

            $($items).css( 'max-height', '200rem' );
            $items[0].classList.remove('is-collapsed');
            $toggle[0].classList.remove('is-active');
            $toggle[0].style.display = 'none';

        } else {

            $toggle[0].style.display = 'flex';

            if ($items[0].classList.contains('is-forced-show') ) {

                $($items[0]).css( 'max-height', '200rem' );
                $items[0].classList.remove('is-collapsed');
                $toggle[0].classList.add('is-active');
                $textShow[0].style.display = 'none';
                $textHide[0].style.display = 'inline';

            } else {

                $($items[0]).css( 'max-height', $height );
                $items[0].classList.add('is-collapsed');
                $toggle[0].classList.remove('is-active');
                $textShow[0].style.display = 'inline';
                $textHide[0].style.display = 'none';

            }

        }
        
	},

    toggleOffers( e ) {

        let $toggle = $(e.currentTarget);
        let $textShow = $toggle.find('[data-mobile-panels-toggle-text-show]');
        let $textHide = $toggle.find('[data-mobile-panels-toggle-text-hide]');

        let $qty = $toggle.data('mobile-panels-toggle');
        if ( !$qty || $qty < 2 )
            $qty = 6;

        let $items = $toggle.prev();
        let $width = window.innerWidth;
        let $item = $items.find('[data-mobile-panels-item]'); // need this - css columns has overflow target item

        let $target = $($item[$qty - 2]);
        let $targetNext = $($item[$qty -1 ]);

        let $height = $target.offset().top - $($items).offset().top + $target.height() + $targetNext.height();

        if ( !$items[0].classList.contains('is-collapsed') ) {

            $($items[0]).css( 'max-height', $height );
            $items[0].classList.add('is-collapsed');
            $items[0].classList.add('is-forced-collapsed');
            $items[0].classList.remove('is-forced-show');
            $toggle[0].classList.remove('is-active');
            $textShow[0].style.display = 'inline';
            $textHide[0].style.display = 'none';
            
        } else {

            $($items[0]).css( 'max-height', '200rem' );
            $items[0].classList.remove('is-collapsed');
            $items[0].classList.add('is-forced-show');
            $items[0].classList.remove('is-forced-collapsed');
            $toggle[0].classList.add('is-active');
            $textShow[0].style.display = 'none';
            $textHide[0].style.display = 'inline';

        }

    }
        
};

export default MobilePanels;