
export const Carousel = {

    init: function() {

        this.$carousel = $('[data-carousel]');

        if ( !this.$carousel.length )
            return;

        this.$container = this.$carousel.find('[data-carousel-items');
        this.$items = this.$container.find('[data-carousel-item]');
        this.$dots = this.$carousel.find('[data-carousel-dots]');

        this.$width = window.innerWidth;
            
        this.$isTouchDevice = 'ontouchstart' in document.documentElement;

        this.$args = {
            autoplay : false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '[data-carousel-prev]',
            nextArrow: '[data-carousel-next]',
            appendDots: '[data-carousel-dots]',
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 750,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        }

        if ( !this.$isTouchDevice && this.$width >= 750 ) {
            this.$args.arrows = true;
            this.$container.addClass('has-buttons');
        } else {
            this.$args.arrows = false;
            this.$container.removeClass('has-buttons');
        }

        if ( this.$width < 1000 || this.$isTouchDevice ) {
            this.$args.dots = true;
        } else {
            this.$args.dots = false;
        }

        this.$container.slick( this.$args );

        $(window).on( 'resize', this.refreshCarousel.bind(this) );

    },

    refreshCarousel() {

        let $width = window.innerWidth;
        let $args = {};

        if ( !this.$isTouchDevice && $width >= 750 ) {
            $args.arrows = true;
            this.$container.addClass('has-buttons');
        } else {
            $args.arrows = false;
            this.$container.removeClass('has-buttons');
        }

        if ( $width < 1000 ) {
            $args.dots = true;
        } else {
            $args.dots = false;
        }

        if ( $args )
                this.$container.slick( 'slickSetOption', $args );
        
        this.$container.slick('refresh');

    }
        
};

export default Carousel;