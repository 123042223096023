
window.$ = window.jQuery;

import '../styles/app.scss';

import { Site } from './modules/site';
import { Slider } from './modules/slider';
import { Carousel } from './modules/carousel';
import { MobilePanels } from './modules/mobilePanels';
import { MobileMenu } from './modules/mobileMenu';
import { Menu } from './modules/menu';
import { LanguagePicker } from './modules/languagePicker';
import { LoginPopup } from './modules/loginPopup';
import { Panel } from './modules/panel';
import { Accordion } from './modules/accordion';
import { Search } from './modules/search';

Site.init();
Slider.init();
Carousel.init();
MobilePanels.init();
MobileMenu.init();
Menu.init();
LanguagePicker.init();
LoginPopup.init();
Panel.init();
Accordion.init();
Search.init();