
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


export const LanguagePicker = {

    init: function() {
        
        this.$picker = $('[data-language-picker]');
        this.$background = this.$picker.find('[data-language-picker-background]');
        this.$button = this.$picker.find('[data-language-picker-button]');

        if ( !this.$picker.length || !this.$background.length || !this.$button.length )
            return;

        this.$isTouchDevice = 'ontouchstart' in document.documentElement;
        
        if ( !this.$isTouchDevice )
            return;

        this.$button.on( 'click', this.toggle.bind(this) );
        this.$background.on( 'click', this.toggle.bind(this) );

    },

	toggle() {

        this.active = !this.active;
        this.$picker.toggleClass('is-active');

        if ( this.active ) {
            disableBodyScroll( this.$picker[0] );
        } else {
            enableBodyScroll( this.$picker[0] );
        }

	}

};

export default LanguagePicker;