
export const Menu = {

    init: function() {
        
        this.$menu = $('[data-menu]');
        this.$parent = this.$menu.find('[data-menu-parent]');

        if ( !this.$menu.length || !this.$parent.length )
            return;

        this.$wrappers = this.$menu.find('[data-menu-children-wrap]');

        this.$isTouchDevice = 'ontouchstart' in document.documentElement;

        this.$parent.on( 'mouseover', this.dropDownPosition.bind(this) );
        this.$menu.parent().on( 'scroll', this.hideDropdowns.bind(this) );

        if ( !this.$isTouchDevice )
            return;

        this.$parent.on( 'click', this.touchClick.bind(this) );
        this.$parent.on( 'focusout', this.removeTouchClass.bind(this) );

    },

    dropDownPosition(e) {

        let $elem = $(e.currentTarget);
        let $wrap = $elem.next('[data-menu-children-wrap]');
        let $children = $wrap.find('[data-menu-children]');

        let $minLeft = $elem.position().left - this.$menu.position().left;

        if ( $minLeft > 0 )
            $minLeft = 0;

        let $widthDifference = $elem.innerWidth() - $children.innerWidth();

        $wrap.css( {
            top: Math.round( $elem.outerHeight() ),
            left: $elem.position().left + $wrap.children().outerWidth() / 2 - $minLeft + $widthDifference / 2
        } );

        $wrap.show();

    },

    hideDropdowns(e) {

        this.$wrappers.hide();

    },

	touchClick(e) {

        let $elem = $(e.currentTarget);

        if ( $elem.hasClass('is-touched') )
            return;

        $elem.addClass('is-touched');
        e.preventDefault();

	},

    removeTouchClass(e) {

        let $elem = $(e.currentTarget);
        $elem.removeClass('is-touched');

    }

};

export default Menu;