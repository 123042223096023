
export const Panel = {

    init: function() {

        // might need to rename this function or incorporate into School Search

        this.$panel = $('[data-panel]');

        if ( !this.$panel.length )
            return;

        this.$tab = this.$panel.find('[data-panel-tab]');

        this.$tab.on( 'click', this.changePanel.bind(this) );

    },

	changePanel(e) {
        
        if ( $(e.currentTarget).hasClass( 'is-active' ) )
            return;

        var $id = $(e.currentTarget).data( 'panel-tab' );

        $('[data-panel-tab]').removeClass( 'is-active' );
        $('[data-panel-tab=' + $id + ']').toggleClass( 'is-active' );

	}
        
};

export default Panel;