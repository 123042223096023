

export const Slider = {

    init: function() {

        this.$slider = $('[data-slider]');

        if ( !this.$slider.length )
            return;

        this.$autoSlide = null;
        this.$defaultDuration = 5000;

        this.$item = this.$slider.find('[data-slider-item]');
        this.$dot = this.$slider.find('[data-slider-dot]');

        if ( this.$item.length < 2 || !this.$dot.length )
            return;

        this.$length = this.$item.length;
        this.$prev = this.$slider.find('[data-slider-prev]');
        this.$next = this.$slider.find('[data-slider-next]');

        if ( !this.$prev.length || !this.$next.length )
            return

        this.$prev.on( 'click', this.prevSlide.bind(this) );
        this.$next.on( 'click', this.nextSlide.bind(this) );
        this.$dot.on( 'click', this.goToSlide.bind(this) );
        
        this.swipeTouchDevices();
        this.firstSlideInit();

    },

    prevSlide() {

        let $active = this.$slider.find('.is-active');
        let $activeSlide = $active.data('slider-item');
        let $target = $activeSlide - 1;

        if ( $activeSlide == 1 )
            $target = this.$length;

        this.goToSlide( null, $target );

	},

	nextSlide() {

        let $active = this.$slider.find('.is-active');
        let $activeSlide = $active.data('slider-item');
        let $target = $activeSlide + 1;

        if ( $activeSlide == this.$length )
            $target = 1;
        
        this.goToSlide( null, $target );

	},

    goToSlide( e, $target = null ) {

        clearTimeout( this.$autoSlide );

        if ( !$target ) {
            if ( $(e.currentTarget).hasClass('is-active') ) {
                return;
            }
            $target = $(e.currentTarget).data('slider-dot');
        }
        
        let $newSlide = this.$slider.find('[data-slider-item=' + $target + ']' );
        let $duration = $newSlide.data('slider-duration');

        if ( !$duration )
            $duration = this.$defaultDuration;

        this.$item.removeClass('is-active');

        if ( $newSlide.data('slider-type') == 'video' )
            this.reloadVideo( $newSlide );

        this.$autoSlide = setTimeout( () => { this.nextSlide() }, $duration );

        $newSlide.addClass('is-active');

        this.$dot.removeClass('is-active');
        this.$slider.find('[data-slider-dot=' + $target + ']').addClass('is-active');

    },

    reloadVideo( $slide ) {

        let $iframe = $slide.find('iframe');

        $iframe.attr( 'src', $iframe.data('src') );
        
    },

    swipeTouchDevices() {

        let $isTouchDevice = 'ontouchstart' in document.documentElement;

        let touchstartX = 0;
        let touchendX = 0;
            
        document.addEventListener('touchstart', e => {
            touchstartX = e.changedTouches[0].screenX;
        })

        document.addEventListener('touchend', e => {
            touchendX = e.changedTouches[0].screenX;
            this.checkDirection( touchstartX, touchendX );
        })
        
    },

    checkDirection( touchstartX, touchendX ) {

        if (touchendX < touchstartX) {
            Slider.nextSlide();
        }

        if (touchendX > touchstartX) {
            Slider.prevSlide();
        }

    },

    firstSlideInit() {

        let $firstSlide = this.$slider.find('[data-slider-item=1]' );
        let $duration = $firstSlide.data('slider-duration');

        if ( !$duration )
            $duration = this.$defaultDuration;

        if ( $firstSlide.data('slider-type') == 'video' )
            this.reloadVideo( $firstSlide );

        this.$autoSlide = setTimeout( () => { this.nextSlide() }, $duration );

    }
        
};

export default Slider;